<template>
  <div class="mod-goods-tie">
    <amis-renderer :schema="schema" />
  </div>
</template>

<script>
import AmisRenderer from '@/components/AmisRenderer';
import schema from './schemas/gen/goods-tie.json';

export default {
  name: 'GoodsTie',
  components: { AmisRenderer },
  data() {
    return {
      schema,
    };
  },
};
</script>

<style lang="scss" scoped></style>
